import React from 'react';
import './App.css';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useEffect } from 'react';
const Blog = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "grey"
  }, []);

  return (
    <div>
      <Container>
        <Row>
          <Card style={{ width: '100%', marginTop: 20 }}>
            <Card.Body>

              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3" style={{ justifyContent: 'center', fontSize: 25 }}>
                <Tab eventKey="home" title="Ian">
                  <Table striped bordered hover responsive >
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Hora</th>
                        <th style={{ textAlign: 'center' }}>Lunes</th>
                        <th style={{ textAlign: 'center' }}>Martes</th>
                        <th style={{ textAlign: 'center' }}>Miercoles</th>
                        <th style={{ textAlign: 'center' }}>Jueves</th>
                        <th style={{ textAlign: 'center' }}>Viernes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:00am - 9:40am</td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PROYECTO MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>SOCIO EMOCIONAL MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TUTORIA MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>CIENCIAS NATURALES MISS MELISA </a> </td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TALLER CALCULO </a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:50am - 10:30am</td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES MISS SARAHI</a></td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES MISS SARAHI</a></td>
                        <td> <a href="https://us05web.zoom.us/j/89498919600?pwd=V0pBazBVOEpkbmkvOStQRG5vLzQwQT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>E. FISICA MISS ROSA</a></td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES MISS SARAHI</a> </td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TALLER LITERARIO</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 10:50am - 11:30am</td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>CIENCIAS NATURALES MISS MELISA</a></td>
                        <td> <a href="https://us05web.zoom.us/j/82501478187?pwd=WU5OWUpsSm1odkNoN1BMelY0NU9kdz09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>FORMACION CIVICA Y ETICA MISS YACKI</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a> </td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 11:40am - 12:20pm</td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 12:30pm - 1:10pm</td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>GEOGRAFIA MISS MARIANA</a></td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES MISS SARAHI</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>GEOGRAFIA MISS MARIANA</a> </td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 1:20am - 2:00pm</td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES MISS SARAHI</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>HISTORIA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77954774097?pwd=WnV4TUdRdHpJaUVOT3RhQWIwaVlPUT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>HISTORIA</a> </td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>
                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="profile" title="Laila">
                  <Table striped bordered hover responsive >
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Hora</th>
                        <th style={{ textAlign: 'center' }}>Lunes</th>
                        <th style={{ textAlign: 'center' }}>Martes</th>
                        <th style={{ textAlign: 'center' }}>Miercoles</th>
                        <th style={{ textAlign: 'center' }}>Jueves</th>
                        <th style={{ textAlign: 'center' }}>Viernes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:00am - 9:40am</td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS MARIANA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS MARIANA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS MARIANA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS MARIANA</a> </td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TALLER DE CALCULO</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:50am - 10:30am</td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TUTORIA. MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>CONOC. MEDIO MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>SOCIO EMOC MISS MELISA</a></td>
                        <td> <a href="https://us04web.zoom.us/j/79097410972?pwd=UVFZdmZUQjdmYUl1eUJLWGVIK0tiQT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>CONOC. MEDIO MISS MELISA</a> </td>
                        <td> <a href="https://us05web.zoom.us/j/82501478187?pwd=WU5OWUpsSm1odkNoN1BMelY0NU9kdz09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>TALLER DE LECTO ESCRITURA. MISS YACKI</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 10:50am - 11:30am</td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us05web.zoom.us/j/89498919600?pwd=V0pBazBVOEpkbmkvOStQRG5vLzQwQT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>E. FISICA MISS ROSA</a> </td>
                        <td> <a href="https://us04web.zoom.us/j/77715712477?pwd=eDExMnpsaWt4SnlzdWsrdVpmMWs1QT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS MARIANA</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 11:40am - 12:20pm</td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>MATEMATICAS</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 12:30pm - 1:10pm</td>
                        <td> </td>
                        <td> <a href="https://us04web.zoom.us/j/77971447847?pwd=RWFmWFNqMVQrQU95VE1hT0ExWktzQT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ESPAÑOL</a></td>
                        <td> <a href="https://us05web.zoom.us/j/82501478187?pwd=WU5OWUpsSm1odkNoN1BMelY0NU9kdz09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PROYECTO MISS MELISA</a></td>
                        <td> </td>
                        <td style={{ textAlign: 'center' }}> ASESORIAS GRUPALES</td>
                      </tr>


                    </tbody>
                  </Table>
                </Tab>
                <Tab eventKey="contact" title="Alexander">
                  <Table striped bordered hover responsive >
                    <thead>
                      <tr>
                        <th style={{ textAlign: 'center' }}>Hora</th>
                        <th style={{ textAlign: 'center' }}>Lunes</th>
                        <th style={{ textAlign: 'center' }}>Martes</th>
                        <th style={{ textAlign: 'center' }}>Miercoles</th>
                        <th style={{ textAlign: 'center' }}>Jueves</th>
                        <th style={{ textAlign: 'center' }}>Viernes</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:00am - 9:40am</td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS SARAHI</a></td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS SARAHI</a></td>
                        <td> <a href="https://us05web.zoom.us/j/83522817811?pwd=T21Gb3lQdHFSVUxjL21DU3BCZ2NQUT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>INGLES. MISS SARAHI</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PENSAMIENTO MATEMATICO</a> </td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>LENGUAJE</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 9:50am - 10:30am</td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>LENGUAJE</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>LENGUAJE</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>LENGUAJE</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>LENGUAJE</a></td>
                        <td> <a href="https://us05web.zoom.us/j/89498919600?pwd=V0pBazBVOEpkbmkvOStQRG5vLzQwQT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>EDU. FISICA. MISS ROSA</a> </td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 10:50am - 11:30am</td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PENSAMIENTO MATEMATICO</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#4db6ac', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PENSAMIENTO MATEMATICO</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PENSAMIENTO MATEMATICO</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>CONOC. DEL MUNDO</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#80deea', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PENSAMIENTO MATEMATICO</a></td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: 'center' }}> 11:40am - 12:20pm</td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#ce93d8', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>DES. SOCIO EMOC</a></td>
                        <td> </td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#EF9A9A ', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PROGRENTIS</a></td>
                        <td> <a href="https://us05web.zoom.us/j/81513015184?pwd=SjF0bXRsRTJBYTFPQmIvNGJZUVA3UT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>PROGRENTIS</a></td>
                        <td> <a href="https://us05web.zoom.us/j/84302134419?pwd=b2ljOStRVFloa0swbU9PUDVJVDAwUT09" style={{ background: '#9fa8da', borderRadius: 5, fontSize: 15, padding: 5, color: 'white', display: 'block', textAlign: 'center' }}>ARTES. MISS ERIKA</a></td>
                      </tr>
                    </tbody>
                  </Table>

                </Tab>
              </Tabs>

            </Card.Body>
          </Card>
        </Row>
      </Container>


    </div>
  );
}

export default Blog;
