import React from 'react';
import './App.css';
import Nav from '../../Components/header';
import Body from '../../Components/body';

function App() {
  return (
    <div className="App">
      <Nav/>
      
      <Body/>
    </div>
  );
}

export default App;
