
import React, {useState} from 'react';
import Toast from 'react-bootstrap/Toast';

const Tostadita = (props) => {
  const [show, setShow] = useState(props.show);

  return (
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{
            position: 'relative',
            minHeight: '100px'
        }}
        >
        <Toast onClose={() => {setShow(false); props.callbackFun()}} show={show} delay={3000} autohide style={{maxWidth:'100%'}}>
          <Toast.Header style={{backgroundColor:props.headerColor}}>           
            <strong className="mr-auto" style={{textAlign:'center',color:'white'}}>{props.label}</strong>
          </Toast.Header>
          <Toast.Body style={{backgroundColor:props.backgroundColor, 
            borderColor:'white', borderWidth:'2px', borderStyle:'solid', color: props.color, fontWeight:'bold'}}>{props.message}</Toast.Body>
        </Toast>
        </div>
  );
}

export default Tostadita;