import React, {useState} from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Toast from './toast';

const Formulario = () =>
{    
    // Declare a new state variable, which we'll call "count"
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('You need to fill all the form values');

    const callback = () =>
    {
        setError(false);
        setSuccess(false);
    }
    const sendMail = () =>
    { 
        if(email.length === 0)
        {
            setMessage('You need to fill all the form values');
            setError(true);
            return;
        }
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
        {           
        }else
        {
            setMessage('You have entered an invalid email address!');
            setError(true);
            return;
        }        

        if(subject.length === 0)
        {
            setMessage('You need to fill all the form values');
            setError(true);
            return;
        }
        if(content.length === 0)
        {
            setMessage('You need to fill all the form values');
            setError(true);
            return;
        }
        axios({
                method: 'post',
                url: 'http://codemajic.net/api/default',
                data: {
                    from: email,
                    subject: subject,
                    content:content
                }                
            }).then(function (response) {
                setSuccess(true);
                setEmail('');
                setSubject('');
                setContent('');
            }).catch(function (error) {
                //error message here
                setSuccess(true);
                setEmail('');
                setSubject('');
                setContent('');
            });
    }
    return (
        <div>
            {error === true ? <Toast    callbackFun={callback} 
                                        show={error} 
                                        backgroundColor={'#222629'} 
                                        label={'Error'}
                                        color={'rgb(134, 194, 50)'}
                                        message={message}
                                        headerColor={'red'}
                                         /> : null}
            <Form>
                <InputGroup className="mb-3">
                    <FormControl
                    controlId="exampleForm.ControlInput1"
                    placeholder="What's your email so I can reach back at you?"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                    />
                    <InputGroup.Append>
                    <InputGroup.Text id="basic-addon2">@youremail.com</InputGroup.Text>
                    </InputGroup.Append>
                    
                </InputGroup>
                <InputGroup className="mb-3">
                    <FormControl
                    placeholder="The subject of your email"
                    aria-label="Recipient's subject"
                    aria-describedby="basic-addon2"
                    onChange={e => setSubject(e.target.value)}
                    value={subject}
                    />
                </InputGroup>
                {success === true ? <Toast callbackFun={callback} 
                                        show={success} 
                                        backgroundColor={'#222629'} 
                                        label={'Aviso'}
                                        color={'#FFF'}
                                        message={'Gracias, revisare tu mensaje y me pondre en contacto'}
                                        headerColor={'rgb(134, 194, 50)'}
                                         /> : null}
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Control as="textarea" rows={3} placeholder="The content of your message" onChange={e => setContent(e.target.value)} value={content}/>
                </Form.Group>                
                {error === true || success === true ? null: <Button variant="info" type="button"  style={{marginBottom:20}} onClick={()=>{sendMail()}}>Send your message</Button>}
            </Form>
        </div>
    );
}

export default Formulario;