import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';
import Form from './form';

const Body = () => {
    return (
        <div>
            <div id="home">
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={12}>
                        <h2 style={{ color: 'rgb(134, 194, 50)', textAlign: 'center'}}>About</h2>
                    </Col>
                </Row>
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={12} md={4}><img alt="" src="assets/img/profile.jpg" style={{ borderRadius: '50%' }}/></Col>
                    <Col xs={12} md={8} style={{ color: '#FFF', textAlign: 'left', fontSize: 18 }}>
                        My name is Salvador Jimenéz Gastélum, I’m a Software Engineer with over 10 years in experience working in a wide range of technologies, from Visual Basic 6.0, Pascal, C++ and Fox-Pro in legacy applications to modern technologies, frameworks and serverless applications.<br />I'm also an old school gamer who prefer rich histories that reflect an aspect of the human beigns or society and culture.<br />
                        Hobbiest game developer with just a few remakes of classic games that I have done in C++ using SDL as graphics library.<br /><br />
                        I consider myself as a one man army guy since most of my knowledge comes from working in projects where caos reign and always make the most out of them, sometimes even jumping in frameworks or technologies that are out of my confort zone and that in the long run enriched my learning path and the diversity of my tech stack.
                    </Col>
                </Row>
            </div>
            <div id="mytoolset"> 
                <Row style={{marginRight:0, marginLeft:0, marginTop:40}}>
                    <Col xs={12}>
                        <h2 style={{ color: 'rgb(134, 194, 50)', textAlign: 'center'}}>My Toolset</h2>
                    </Col>
                </Row>
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={6} md={2}><Image src="assets/img/sass.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/mysql.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/ionic.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/laravel.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/net.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/react.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                </Row>
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={6} md={2}><Image src="assets/img/codeigniter.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/js.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/angular.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/sql.jpg" style={{width: '6rem', height: 'auto', marginTop:20}} roundedCircle fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/azure.jpg" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/bootstrap.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                </Row>
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={6} md={2}><Image src="assets/img/linux.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/activedirectory.jpg" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/cpp.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/vue.png" style={{width: '6rem', height: 'auto'}} roundedCircle fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/cocos.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                    <Col xs={6} md={2}><Image src="assets/img/unity.png" style={{width: '6rem', height: 'auto'}} fluid/></Col>
                </Row>
            </div>
            <div id="portfolio">
                <Row style={{marginRight:0, marginLeft:0, marginTop:20}}>
                    <Col xs={12} md={{span:8, offset:2}}>
                        <h2 style={{ color: 'rgb(134, 194, 50)',marginTop:20, marginBottom:20}}>Portfolio</h2>
                        <Carousel controls={false}>
                            <Carousel.Item interval={3000}>
                                <Image
                                className="d-block w-100"
                                src="assets/img/namaste.jpg"
                                alt="First slide"      
                                        style={{marginBottom:20}}             
                                />
                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Namaste Woocommerce</h3>
                                <p style={{color:'#FFF'}}>Namaste ecommerce developed in wordpress and with a custom plugin to connect to a desktop POS</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/namaste_pos.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Namaste POS Client</h3>
                                <p style={{color:'#FFF'}}>Namaste POS version developed in electron and materialize css</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/conredes.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Con Redes</h3>
                                <p style={{color:'#FFF'}}>Conredes landing site for the borderplex group to create a space to reflect their trajectory</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/etronix.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Etronix Blog and Ecommerce</h3>
                                <p style={{color:'#FFF'}}>Blog and Ecommerce created in laravel, jquery and materialize css connected with paypal for checkout</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/mrpago.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Mr Pago platform</h3>
                                <p style={{color:'#FFF'}}>Web platform for the customers to handle payments for different services in a safe way</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/ethicon.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Checkin for Ethicon</h3>
                                <p style={{color:'#FFF'}}> Platform and mobile app for Jhonson and Jhonson company coded in ASP.Net and React Native</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/instalate.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Instalate drupal</h3>
                                <p style={{color:'#FFF'}}>Drupal / laravel website to mine and fetch real state properties in Spain from a 3rd party API</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item interval={3000}>
                                <img
                                className="d-block w-100"
                                src="assets/img/heineken.jpg"
                                alt="Third slide"
                                style={{marginBottom:20}}
                                />

                                <Carousel.Caption style={{position:'sticky'}}>
                                <h3 style={{color:'#FFF'}}>Heineken Web App</h3>
                                <p style={{color:'#FFF'}}>Web platform done in laravel to handle promotions and gifts for bars in las canarias</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </div>
            <div id="contact">
                <Row style={{marginRight:0, marginLeft:0}}>
                    <Col xs={12} md={{span:6, offset:3}}>
                        <h2 style={{ color: 'rgb(134, 194, 50)', marginTop:20,  marginBottom:20}}>Contact me</h2>
                        <div class="LI-profile-badge"  data-version="v1" data-size="large" data-locale="es_ES" data-type="vertical" style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}} data-theme="dark" data-vanity="salvador-jimenez-gastelum"></div>
    
                        <p style={{color:'#FFF', marginTop:20, marginBottom:10}}>Always a pleasure to hear about new business opportunities, you can contact me with the form below.</p>
                        <Form/>
                    </Col>
                </Row>                
            </div>
        </div>
    );
}

export default Body;
